import { createGlobalStyle } from 'styled-components';

import font from '../assets/fonts/ccsign.woff';

const OrchidTypography = createGlobalStyle`
  @font-face {
    font-family: CCSignLanguage;
    src: url(${font});
  }
  html {
    font-family: CCSignLanguage, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--black);
  }
  p, li {
    letter-spacing: 0.5px;
  }
  h1,h2,h3,h4,h5,h6 {
    font-weight: normal;
    margin: 0;
  }
  a {
    color: var(--black);
    text-decoration: none;
  }

  .center {
    text-align: center;
  }

  .inner {
    display: flex;
    justify-content: center; /* align horizontal */
    align-items: center; /* align vertical */
  }

  .tilt {
    transform: rotate(-2deg);
  }
`;

export default OrchidTypography;
